@use "theme/variables" as *;

.cc-window {
    background: linear-gradient(to bottom, $default-5, $default-4);
    border-top: 2px solid $default-4;
    color: $grey-1;
    opacity: 1;
    transition: opacity 1s ease;
}

.cc-window.cc-invisible {
    opacity: 0;
}

.cc-animate.cc-revoke {
    transition: transform 1s ease;
}

.cc-animate.cc-revoke.cc-top {
    transform: translateY(-2em);
}

.cc-animate.cc-revoke.cc-bottom {
    transform: translateY(2em);
}

.cc-animate.cc-revoke.cc-active.cc-bottom,
.cc-animate.cc-revoke.cc-active.cc-top,
.cc-revoke:hover {
    transform: translateY(0);
}

.cc-grower {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
}

.cc-revoke,
.cc-window {
    box-sizing: border-box;
    display: flexbox;
    display: flex;
    flex-wrap: nowrap;
    font-family: $font;
    font-size: $font-d;
    line-height: 1.5em;
    overflow: hidden;
    position: fixed;

    // (!) In front of the Tawk widget
    z-index: 10000000000;
}

.cc-window.cc-static {
    position: static;
}

.cc-window.cc-floating {
    flex-direction: column;
    max-width: 24em;
    padding: 2em;
}

.cc-window.cc-banner {
    align-items: center;
    -ms-flex-align: center;
    flex-direction: row;
    padding: 1em 1.8em;
    width: 100%;
}

.cc-revoke {
    padding: 0.5em;
}

.cc-header {
    font-size: 18px;
    font-weight: 700;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
    cursor: pointer;
}

.cc-link {
    color: $info-3;
    font-weight: 600;

    &:hover {
        color: $info-2;
    }
}

.cc-btn {
    align-items: center;
    background: $info-3;
    color: $white;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    height: $spacer-5;
    justify-content: center;
    line-height: $spacer-5;
    margin: 0;
    text-decoration: none;
    width: 100px;

    &:active,
    &:focus,
    &:hover {
        background: $info-2;
        color: $white;
    }
}

.cc-revoke.cc-top {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    left: 3em;
    top: 0;
}

.cc-revoke.cc-bottom {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    bottom: 0;
    left: 3em;
}

.cc-revoke.cc-left {
    left: 3em;
    right: unset;
}

.cc-revoke.cc-right {
    left: unset;
    right: 3em;
}

.cc-top {
    top: 1em;
}

.cc-left {
    left: 1em;
}

.cc-right {
    right: 1em;
}

.cc-bottom {
    bottom: 1em;
}

.cc-floating > .cc-link {
    margin-bottom: 1em;
}

.cc-floating .cc-message {
    display: block;
    margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
    flex: 1 0 auto;
}

.cc-banner.cc-top {
    left: 0;
    right: 0;
    top: 0;
}

.cc-banner.cc-bottom {
    bottom: 0;
    left: 0;
    right: 0;
}

.cc-banner .cc-message {
    display: block;
    flex: 1 1 auto;
    margin-right: 1em;
    max-width: 100%;
}

.cc-compliance {
    align-content: space-between;
    align-items: center;
    display: flexbox;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-line-pack: justify;
}

.cc-floating .cc-compliance > .cc-btn {
    flex: 1;
}

.cc-btn + .cc-btn {
    margin-left: 0.5em;
}

@media print {

    .cc-revoke,
    .cc-window {
        display: none;
    }
}

@media screen and (max-width: 900px) {

    .cc-btn {
        white-space: normal;
    }
}

@media screen and (max-width: 414px) and (orientation: portrait),
    screen and (max-width: 736px) and (orientation: landscape) {

    .cc-window.cc-top {
        top: 0;
    }

    .cc-window.cc-bottom {
        bottom: 0;
    }

    .cc-window.cc-banner,
    .cc-window.cc-floating,
    .cc-window.cc-left,
    .cc-window.cc-right {
        left: 0;
        right: 0;
    }

    .cc-window.cc-banner {
        align-items: unset;
        flex-direction: column;
    }

    .cc-window.cc-banner .cc-compliance {
        flex: 1 1 auto;
    }

    .cc-window.cc-floating {
        max-width: none;
    }

    .cc-window .cc-message {
        margin-bottom: 1em;
    }

    .cc-window.cc-banner .cc-message {
        margin-right: 0;
    }
}
