@use "theme/variables" as *;

h1,
h2,
legend,
h3,
h4,
h5,
h6 {
    line-height: 1em;
    margin: ($spacer-2 * 1.25) 0 ($spacer-1) 0;
}

h2 {
    color: $info-2;
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.1rem;
}

p {
    line-height: 1.5em;
    margin: 0 auto $spacer-2 auto;
}

a {
    color: $info-1;
    cursor: pointer;

    &:hover {
        color: $brand-3;
    }

    &.disabled {
        color: $grey-4;
        cursor: default;
        pointer-events: none;
    }
}

small {
    color: $grey-3;
    font-size: $font-s;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}

code,
pre {
    font-family: CutiveMono, monospace;
}

pre {
    margin: 0;
    overflow-x: auto;
}

abbr {
    border-bottom: 1px dotted $grey-3;
}

del {
    text-decoration: line-through;
}

figure {
    margin: 0;
}

hr {
    border-color: $grey-5;
    clear: both;
    margin: 2.5rem $spacer-2;
}

ul,
ol {
    margin: 0 auto $spacer-2 auto;
}

.text-confirm {
    color: $success-3;
}
