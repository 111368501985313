@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.order-title {
    color: $info-2;
    display: flex;
    margin-bottom: $spacer-1;

    .header {
        align-items: center;
        display: flex;
        margin-right: $spacer-1;

        .c-icon {
            margin-right: $spacer-05;
        }

        .info-icon {
            cursor: help;
            margin-left: $spacer-1;
        }

        .text {
            font-size: $font-l;
            text-transform: uppercase;
        }
    }

    .help {
        align-items: center;
        color: $grey-3;
        display: flex;
        max-width: 500px;
    }
}

.order-subtitle {
    align-items: center;
    background: $default-6;
    color: $default-3;
    display: flex;
    font-size: $font-d;
    font-weight: 600;
    margin-top: $spacer-05;
    padding: $spacer-1;
    text-transform: uppercase;
    width: 100%;

    &:not(:nth-of-type(1)) {
        margin-top: $spacer-6;
    }

    svg {
        @include square($icon-d);

        margin-right: $spacer-05;
    }
}
