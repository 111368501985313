@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.alert {
    border: 1px solid transparent;
    border-radius: $border-radius;
    padding: $spacer-2;

    // Headings for larger alerts

    h4 {
        color: inherit;
        margin-top: 0;
    }

    // Provide class for links that match alerts

    .alert-link {
        font-weight: 600;
    }

    // Improve alignment and spacing of inner content

    > p,
    > ul {
        margin-bottom: 0;
    }

    > p + p {
        margin-top: 5px;
    }
}

.alert-success {
    @include alert-variant($success-6, $success-4, $success-1);
}

.alert-info {
    @include alert-variant($grey-6, $info-5, $info-1);
}

.alert-warning {
    @include alert-variant($warning-5, $warning-4, $warning-1);
}

.alert-danger {
    @include alert-variant($danger-5, $danger-4, $danger-1);
}
