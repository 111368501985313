@use "theme/variables" as *;

.soi-group {
    background: $default-6;
    border: 2px dashed;
    margin-bottom: $spacer-4;

    .collection-subtitle {
        background: $default-5;
        color: $default-2;
        font-weight: 600;
        margin: 0 $spacer-1;
        padding: $spacer-2 $spacer-1;
        text-transform: uppercase;
    }

    .c-collection-view {
        padding: $spacer-1;
        padding-top: 0;

        .loading-observable {
            display: none;
        }
    }

    > .title {
        align-items: center;
        display: flex;
        font-size: $font-l;
        padding-bottom: $spacer-2;
        padding-left: $spacer-1;
        padding-top: $spacer-1;

        svg {
            margin-right: $spacer-05;
        }
    }

    &.info {
        background: $info-6;
        border-color: $info-4;

        > .title {

            svg {
                color: $info-1;
            }
        }
    }


    &.warning {
        background: $warning-6;
        border-color: $warning-4;

        > .title {
            color: $warning-1;
        }
    }

    &.success {
        background: $success-6;
        border-color: $success-4;

        > .title {
            color: $success-1;
        }
    }
}
