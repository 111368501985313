@use "theme/variables" as *;

table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;

    tr {

        &:nth-of-type(odd) {
            background: $white;
            color: $grey-1;
            transition: background 0.3s ease;

            &:hover {
                background: $grey-6;
            }
        }

        &:nth-of-type(even) {
            background: $grey-6;
            color: $grey-1;

            &:hover {
                background: $grey-5;
            }
        }
    }
}

th {
    text-align: left;
}

tfoot td {
    border-top: 2px solid $grey-1;
}
