@use "theme/variables" as *;
@use "common/lib/mixins" as *;

*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    --app-height: 100%;
}

html,
body {
    height: var(--app-height);
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

html {
    color: $grey-1;
    line-height: 1.5rem;
}

body {
    margin: 0;
    overflow: hidden;

    a,
    a:hover {
        text-decoration: none;
    }

    hr {
        margin: $spacer-2 0;
    }

    h2 {
        font-size: $font-xl;
        margin-bottom: $spacer-05;
        margin-top: 0;
    }

    h3 {
        align-items: center;
        display: flex;
        font-size: $font-l;
        margin-bottom: $spacer-2;

        .fa {
            margin-right: $spacer-05;
        }
    }

    h4 {
        font-size: $font-d;
        margin-bottom: $spacer-2;
        text-transform: uppercase;
    }

    .help-block {
        align-items: center;
        background: $default-5;
        display: flex;
        max-width: 400px;
        padding: $spacer-1;

        svg {
            @include square($icon-xl);

            color: $default-3;
            margin-right: $spacer-05;
        }

        .text {
            color: $default-1;
            flex: 1;
            font-size: $font-s;
            font-style: italic;
        }
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Webkit/Blink's (Chrome/Safari) default scrollbars don't fit
// with the current design of separate scrolling filters.
// Firefox already uses overlay scrollbars, so we use the
// default scrollbars there. In all other cases, the custom
// scrollbar styling is applied. The filter-panel container's
// layout requires an overlay scrollbar. It has two scrollers;
// each separate filter and its parent container. The parent
// container uses a custom overlay scrollbar, except for Firefox.

::-webkit-scrollbar {
    height: $spacer-1;
    width: $spacer-1;
}

::-webkit-scrollbar-track { background: $default-5; }
::-webkit-scrollbar-thumb { background: rgb(0 0 0 / 40%); }


::selection {
    background: $brand-3;
    color: $brand-5;
}
